import Link from "next/link";
import React from "react";
import styles from "./Footer.module.css";
import Constant from "@/staticData/constant";
import {
  CommonElements,
  UIElements,
} from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { HiOutlineMail } from "react-icons/hi";
import { BsDiscord, BsInstagram } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import { ImLinkedin } from "react-icons/im";

function KeyLinks() {
  return (
    <div className={styles["root-contact-us"]}>
      <div className={styles["footer-headings"]}>Contact Us</div>
      <div className={styles["email-div"]}>
        <HiOutlineMail className={styles["email-icon"]} />
        <span className="contact-email-span">hello@outscal.com</span>
      </div>
      <div className={styles["social-media-icons-div"]}>
        <Link
          href={Constant.OUTSCAL_DISCORD_SERVER}
          rel="nofollow"
          target="_blank"
          data-analytics={[UIElements.LINK, "Discord", CommonElements.FOOTER]}
        >
          <BsDiscord className={styles["social-media-icon"]} aria-label="Discord" />
        </Link>
        <Link
          href="https://www.instagram.com/outscalgameschool"
          rel="nofollow"
          target="_blank"
          data-analytics={[UIElements.LINK, "Instagram", CommonElements.FOOTER]}
        >
          <BsInstagram className={styles["social-media-icon"]} aria-label="Instagram" />
        </Link>
        <Link
          href="https://www.youtube.com/@Outscal"
          rel="nofollow"
          target="_blank"
          data-analytics={[UIElements.LINK, "Youtube", CommonElements.FOOTER]}
        >
          <AiFillYoutube className={styles["social-media-icon"]} aria-label="Youtube" />
        </Link>
        <Link
          href="https://www.linkedin.com/school/outscal"
          rel="nofollow"
          target="_blank"
          data-analytics={[UIElements.LINK, "Linkedin", CommonElements.FOOTER]}
        >
          <ImLinkedin className={styles["social-media-icon"]} aria-label="Linkedin" />
        </Link>
      </div>
      <div className={styles["row-div"]}>Made in INDIA 💛💙</div>
    </div>
  );
}

export default KeyLinks;
