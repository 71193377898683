import Link from "next/link";
import React from "react";
import styles from "./Footer.module.css";
import {
  CommonElements,
  UIElements,
} from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

function Company() {
  return (
    <div className={styles["root-company"]}>
      <div className={styles["footer-headings"]}>Company</div>
      <ul className={styles["links-list"]}>
        <li className={styles["links"]}>
          <Link
            href="https://www.linkedin.com/school/outscal"
            data-analytics={[
              UIElements.LINK,
              "About Us",
              CommonElements.FOOTER,
            ]}
            rel="nofollow"
          >
            About Us
          </Link>
        </li>
        <li className={styles["links"]}>
          <Link
            href="/terms-of-service"
            data-analytics={[
              UIElements.LINK,
              "Terms Of Service",
              CommonElements.FOOTER,
            ]}
            rel="nofollow"
          >
            Terms Of Service
          </Link>
        </li>
        <li className={styles["links"]}>
          <Link
            href="/privacy-policy"
            data-analytics={[
              UIElements.LINK,
              "Privacy Policy",
              CommonElements.FOOTER,
            ]}
            rel="nofollow"
          >
            Privacy Policy
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Company;
