import Link from "next/link";
import React from "react";
import styles from "./Footer.module.css";
import Constant from "@/staticData/constant";
import {
  CommonElements,
  UIElements,
} from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
function KeyLinks() {
  return (
    <div className={styles["root-key-links"]}>
      <div className={styles["footer-headings"]}>Key Links</div>
      <ul className={styles["links-list"]}>
        <li className={styles["links"]}>
          <Link
            href="https://outscal.com/jobs"
            data-analytics={[UIElements.LINK, "Jobs", CommonElements.FOOTER]}
          >
            Jobs
          </Link>
        </li>
        <li className={styles["links"]}>
          <Link
            href="/courses"
            data-analytics={[UIElements.LINK, "Courses", CommonElements.FOOTER]}
          >
            Courses
          </Link>
        </li>
        <li className={styles["links"]}>
          <Link
            href={Constant.OUTSCAL_DISCORD_SERVER}
            data-analytics={[UIElements.LINK, "Discord", CommonElements.FOOTER]}
            rel="nofollow"
          >
            Discord
          </Link>
        </li>
        <li className={styles["links"]}>
          <Link
            href="https://outscal.com/practice"
            data-analytics={[
              UIElements.LINK,
              "Practice",
              CommonElements.FOOTER,
            ]}
            rel="nofollow"
          >
            Practice
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default KeyLinks;
