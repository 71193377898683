import AboutOutscal from "./AboutOutscal.view";
import ContactUs from "./ContactUs.view";
import KeyLinks from "./KeyLinks.view";
import Company from "./Company.view";
import styles from "./Footer.module.css";

export default function Footer() {
  return (
    <section data-outscal-section className={styles["root"]}>
      <AboutOutscal />
      <Company />
      <KeyLinks />
      <ContactUs />
    </section>
  );
}
