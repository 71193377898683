import Image from "next/image";
import Link from "next/link";
import React from "react";
import styles from "./Footer.module.css";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

function AboutOutscal() {
  return (
    <div className={styles["root-about-outscal"]}>
      <Link
        className={styles["outscal-logo"]}
        href="/jobs"
        data-analytics={[UIElements.LINK, "Outscal Logo"]}
      >
        <Image
          quality={1}
          alt={"Outscal Logo"}
          width={28}
          height={28}
          src={"/assets/images/animatedlogo.gif"}
        />
        <Image
          quality={1}
          alt={"outscal logo"}
          width={100}
          height={28}
          src={"/assets/images/fullTextLogo.png"}
        />
      </Link>
      <div className={styles["about-outscal-descp"]}>
        A global community of game builders. Helping people upskill and land
        jobs in the best gaming studios.
      </div>
    </div>
  );
}

export default AboutOutscal;
